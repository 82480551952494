import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Layout from '../layouts/layout';
import * as classes from './blog-list.module.scss';

const BlogList = ({ data, pageContext, location }) => {
    const { language } = pageContext;
    const allPosts = data.allGhostPost ? data.allGhostPost.edges : [];
    const filteredPosts = allPosts.filter(({ node }) => {
        return node.tags.some(tag => tag.name === `lang:${language}`);
    });

    const [ displayedPosts, setDisplayedPosts ] = useState(filteredPosts.slice(0, 10));
    const [ ref, inView ] = useInView({
        threshold: 0
    });

    React.useEffect(() => {
        if (inView) {
            setDisplayedPosts(prev => [
                ...prev,
                ...filteredPosts.slice(prev.length, prev.length + 10)
            ]);
        }
    }, [ inView, filteredPosts ]);

    console.log('filteredPosts', filteredPosts, 'displayedPosts', displayedPosts);
    console.log('pageContext', pageContext);

    return (
        <Layout location={location}>
            <h1 className={classes.Title}>Blog</h1>
            <h2 className={classes.Subtitle}>
                <Trans i18nKey="blog:subTitle">
                    <div>Stay updated on the latest news,<br />updates and events from AIDA</div>
                </Trans>
            </h2>
            <div className={classes.PostsContainer}>
                <div className={classes.Robot}>
                    <StaticImage src="../images/robot.png" alt="AIDA Robot" width={257} height={194} />
                </div>
                <div className="container p-0">
                    <div className={classes.Posts}>
                        {displayedPosts.map(({ node }) => {
                            const tagsToDisplay = node.tags.filter(tag => tag.name.indexOf('lang:') === -1);
                            return <Link to={`/${language}/blog/${node.slug}/`} key={node.id} className={classes.Post}>
                                <div className={classes.Image}>
                                    <div className={classes.Tags}>
                                        {tagsToDisplay.map(tag => <div key={`tag-${tag.id}`}>{tag.name}</div>)}
                                        {tagsToDisplay.length === 0 && <>AIDA</>}
                                    </div>
                                    {node.remote_image?.childImageSharp?.gatsbyImageData && (
                                        <GatsbyImage
                                            image={node.remote_image.childImageSharp.gatsbyImageData}
                                            alt={node.feature_image_alt || node.title}
                                            className={classes.FeaturedImage}
                                        />
                                    )}
                                </div>
                                <div className={classes.Title}>
                                    {node.title}
                                </div>
                                <div className={classes.Content}>
                                    {node.excerpt || node.html || ''}
                                </div>
                                <div className={classes.Date}>{new Date(node.published_at).toLocaleDateString()}</div>
                            </Link>;
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query BlogListQuery($language: String!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          id
          title
          slug
          html
          excerpt
          published_at
          feature_image
          feature_image_alt
          remote_image {
            childImageSharp {
              gatsbyImageData(
                width: 400,
                height: 225,
                layout: CONSTRAINED,
                placeholder: DOMINANT_COLOR,
                transformOptions: {
                  fit: COVER,
                  cropFocus: CENTER
                }
              )
            }
          }
          tags {
            id
            name
          }
        }
      }
    }
    locales: allLocale(
      filter: {ns: {in: ["common", "blog"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BlogList;
